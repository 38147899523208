import styled from "styled-components";
import card from "../assets/card.svg";
import boleto from "../assets/boleto.svg";
import pix from "../assets/pix.svg";

const Container = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 150px;
    height: 120px;
    padding: 10px;
    @media only screen and (max-width: 860px) {
        width: 100%;
    }
`;
const Image = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 50px;
    }
`;

const Contribute = () => {
    return (
        <Container>
            <Content>
                <p>Cartão</p>
                <Image>
                    <img src={card} alt="Cartão"/>
                </Image>
            </Content>
            <Content>
                <p>Boleto</p>
                <Image>
                    <img src={boleto} alt="Boleto"/>
                </Image>
            </Content>
            <Content>
                <p>Pix</p>
                <Image>
                    <img src={pix} alt="Pix"/>
                </Image>
            </Content>
        </Container>
    )
};
export default Contribute;