import styled from "styled-components";
import logo from '../assets/logo.png';

const StyledLogo = styled.img`
    height: 100px;
`;
const Links = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 300;
    a {
        color: #fff;
        padding: 5px;
        text-decoration: none;
    }
`;
const StyledFooter = styled.footer`
    width: 100%;
    height: 200px;
    background-color: #1A2635;
    padding: 0 130px;
    flex-shrink: 0;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 860px) {
        justify-content: center;
        flex-direction: column;
        gap: 50px;
        padding: 20px 0;
        height: auto;
        ${Links} {
            justify-content: center;
            align-items: center;
        }
    }
`;

const Footer = () => {
    return (
        <StyledFooter>
            <StyledLogo src={logo}/>
            <Links>
                <a href="#header">Início</a>
                <a href="#sonhe">Sonhe</a>
                <a href="#contribua">Contribua</a>
                <a href="#obra">Acompanhe a obra</a>
            </Links>
        </StyledFooter>
    )
}
export default Footer;