import { useState } from "react";
import styled from "styled-components";
import logo from '../assets/logo.png';
import {BiMenu} from 'react-icons/bi';
import Button from "./button";

const StyledButtons = styled.ul`
    display: flex;
    height: 100%;
`;
const StyledLogo = styled.img`
    height: 42px;
`;
const StyledButton = styled(Button)`
    background: none;
    display: flex;
    height: 100%;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    color: #fff;
`;
const OpenBtn = styled(Button)`
    position: absolute;
    right: 0px;
    top: 0px;
    height: 77px;
    width: 77px;
    visibility: hidden;
    border: none;
    outline: none;
    background: none;
    color: #fff;
`;
const StyledHeader = styled.header`
    width: 100%;
    height: 77px;
    background-color: #1A2635;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;

    padding: 0 130px;
    justify-content: space-between;

    @media only screen and (max-width: 860px) {
        justify-content: center;
        flex-direction: column;
        padding: 20px 0;
        height: ${p => p.opened ? 'auto' : '77px'};

        ${StyledButtons} {
            margin-top: ${p => p.opened ? '30px' : '0'};
            height: ${p => p.opened ? 'auto' : '0'};
            width: 100%;
            flex-direction: column;
        }
        ${StyledButton} {
            padding: 20px;
            box-sizing: border-box;
            width: 100%;
            height: auto;
        }
        ${OpenBtn} {
            visibility: visible;
        }
    }
    transition: all ease .5s;
`;


const Header = () => {
    const [opened, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(!opened);
    }
    const anchor = (to) => {
        window.location = "#" + to;
    }
    return (
        <StyledHeader opened={opened}>
            <StyledLogo src={logo}/>
            <StyledButtons>
                <StyledButton onClick={() => anchor("header")}>
                    Ínicio
                </StyledButton>
                <StyledButton onClick={() => anchor("sonhe")}>
                    Sonhe
                </StyledButton>
                <StyledButton onClick={() => anchor("contribua")}>
                    Contribua
                </StyledButton>
                <StyledButton onClick={() => anchor("obra")}>
                    Acompanhe a obra
                </StyledButton>
            </StyledButtons>
            <OpenBtn onClick={handleOpen}>
                <BiMenu size={30}/>
            </OpenBtn>
        </StyledHeader>
    )
}
export default Header;