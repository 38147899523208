import styled from 'styled-components';
import Header from './components/header';
import Construction from './components/construction';
import Section from './components/section';
import Pastors from './components/pastors';
import { PrimaryButton } from './components/button';
import Contribute from './components/contribute';
import Footer from './components/footer';
import HeaderGallery from './components/headerGallery';
import Gallery from './components/gallery';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useState } from 'react';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
`;

function App() {
  const [progressAnim, setProgressAnim] = useState(false);

  return (
    <MainContainer id="container">
      <Header id="header"/>
        <HeaderGallery/>
        <AnimationOnScroll scrollableParentSelector="#container" animateIn="animate__fadeInUp"> 
          <Section>
            <iframe width="100%" height="400" src="https://www.youtube.com/embed/HmQd8NjFroM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </Section>
        </AnimationOnScroll>
        <AnimationOnScroll scrollableParentSelector="#container" animateIn="animate__fadeInUp"> 
          <Section id="sonhe" background="#F5F5FC">
            <h2>Conheça o Projeto Sonhe</h2>
            <p>
            Acreditamos que a igreja é a solução para o mundo e
            temos convicção do nosso chamado para influenciar e

            alcançar as nações.

            Através do Projeto Sonhe daremos um grande passo em
            direção aos planos que o céu tem para nós e ansiamos
            que o Reino seja estabelecido em sua plenitude.
            O novo campus será um local de chegadas e envios, uma
            plataforma de lançamento de filhos flechas que vão

            impactar globalmente.

            A chama apostólica e profética está acesa, vamos
            assumir o bastão e catalisar o destino que nos foi
            entregue. Por isso, convocamos todos os avivalistas para
            abraçar esse sonho e gerar o mover que a nós foi

            confiado.
            Sonhe - Faça acontecer!
            </p>
          </Section>
        </AnimationOnScroll>
        <AnimationOnScroll scrollableParentSelector="#container" animateIn="animate__fadeInUp"> 
          <Section>
            <h2>Galeria</h2>
            <Gallery/>
          </Section>
        </AnimationOnScroll>
        <AnimationOnScroll background="#F5F5FC" scrollableParentSelector="#container" animateIn="animate__fadeInUp"> 
          <Section>
            <h2>Palavra Pastoral</h2>
            <p style={{fontStyle: 'italic'}}>
            “Como dizem as Sagradas Escrituras:
            ‘Estamos como aqueles que sonham’. Nossos
            olhos estão vendo a realização de um projeto que
            nasceu no coração de Deus: a construção de um

            novo campus.

            Nos foi revelado o futuro, gostamos do que vimos
            e estamos correndo para lá. Sabemos que é um
            grande desafio, porém, confiantes em Deus,
            em sua promessa e juntos com toda a família
            espiritual, nos empenhamos em favor desta causa
            com todas as nossas forças.
            Agradecemos a todos que até aqui contribuíram
            com este sonho e queremos convocar todos, para
            juntos caminharmos em direção a realização

            deste plano.”
            </p>
            <Pastors/>
          </Section>
        </AnimationOnScroll>
        <AnimationOnScroll afterAnimatedIn={() => setProgressAnim(true)} afterAnimatedOut={() => setProgressAnim(false)} scrollableParentSelector="#container" animateOut='animate__fadeOut' animateIn="animate__fadeInUp"> 
          <Section id="obra">
            <h2>Estágio da obra</h2>
            <Construction animate={progressAnim}/>
          </Section>
        </AnimationOnScroll>
        <AnimationOnScroll scrollableParentSelector="#container" animateIn="animate__fadeInUp"> 
          <Section id="contribua" background="#F5F5FC">
            <h2>Contribua</h2>
            <Contribute/>
            <PrimaryButton onClick={() => {window.location = "https://verdadeigreja.com/doacoes/1421/projeto-sonhe/pagamento"}}>
              Contribua online
            </PrimaryButton>
          </Section>
        </AnimationOnScroll>
        <Footer/>
    </MainContainer>
  );
}

export default App;
