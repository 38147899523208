import styled from "styled-components";
import photo from "../assets/pastores.png";

const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 10px;

    img {
        width: 180px;
        height: auto;
    }
    div {
        width: 150px;
        text-align: left;
    }
`;

const Pastors = () => {
    return (
        <StyledContainer>
            <img alt="pastores" src={photo}/>
            <div>
                <h3>Marcelo e Andréa Cruz</h3>
                <p style={{fontStyle: 'italic'}}>pastores líderes</p>
            </div>
        </StyledContainer>
    )
}
export default Pastors;