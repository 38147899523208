import styled from "styled-components";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useEffect, useState } from "react";

const Percent = styled.div`
    width: 150px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #F5F5FC;
    h2 {
        font-size: 40px;
    }
    flex-grow: 1;
`;
const Texts = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    flex-shrink: 1;
`;
const Table = styled.div`
    display: flex;
    gap: 10px;
    height: 180px;
    @media only screen and (max-width: 860px) {
        flex-direction: column;
        height: auto;
        ${Texts} {
            height: auto;
            gap: 10px;
        }
        ${Percent} {
            width: 100%;
            height: 200px;
        }
    }
    transition: all ease 1s;
`;
const Row = styled.div`
    display: flex;
    width: 300px;
    align-items: center;
    background-color: #F5F5FC;
    p {
        width: 100%;
        text-align: left;
        padding: 5px;
        box-sizing: border-box;
    }
    div {
        width: 100%;
        margin-right: 5px;
    }
`;
var timer;
const defaultProgress = {
    projects: {
        progress: 0, // to 100
        label: "0%" // to finish
    },
    construction: {
        progress: 0, //to 53,
        label: "0%" // to 53%
    },
};

const Construction = ({animate}) => {
    var [progress, setProgress] = useState(defaultProgress);
    useEffect(() => {
        
        if (timer)
        {
            clearInterval(timer);
            setProgress({projects: {
                progress: 0, // to 100
                label: "0%" // to finish
            },
            construction: {
                progress: 0, //to 53,
                label: "0%" // to 53%
            }});
        }

        if (animate === true)
        {
            timer = setInterval(() => {
                var projects = progress.projects;
                var construction = progress.construction;
                console.log(construction);

                if (projects.progress < 100)
                {
                    projects.progress += 10;
                    projects.label = projects.progress + "%";
                }
                else
                {
                    projects.progress = 100;
                    projects.label = "Concluído";
                    clearInterval(timer);
                }
                if (construction.progress < 33)
                {
                    construction.progress += 5;
                    construction.label = construction.progress + "%";
                }
                else
                {
                    construction.progress = 33;
                    construction.label = "33%";
                }
                setProgress({projects: projects, construction: construction});
            }, 100);
        }
    }, [animate])
    return (
        <Table>
            <Texts>
                <Row>
                    <p>Licenças</p>
                    <ProgressBar variant="success" now={progress.projects.progress} label={progress.projects.label}/>
                </Row>
                <Row>
                    <p>Aterro</p>
                    <ProgressBar now={0} label={`Próxima etapa`}/>
                </Row>
                <Row>
                    <p>Obra</p>
                    <ProgressBar now={0} label={`Próxima etapa`}/>
                </Row>
            </Texts>
            <Percent>
                <h2>{progress.construction.label}</h2>
                <p>Em construção</p>
            </Percent>
        </Table>
    )
}
export default Construction;