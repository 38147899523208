import Carousel from 'react-bootstrap/Carousel';
import styled from 'styled-components';
import sonhe from '../assets/fotos/header.jpg';

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 400px;
  object-position: right bottom;
`;
const HeaderGallery = () => {
    return (
      <Carousel controls={false}>
        <Carousel.Item>
          <Image
            src={sonhe}
            alt="First slide"
          />
        </Carousel.Item>
      </Carousel>
    )
}
export default HeaderGallery;