import styled from "styled-components";

const StyledSection = styled.section`
    padding: 50px 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
    flex-direction: column;
    color: #7B7B7B;
    line-height: 25px;
    ${p => p.background ? "background: " + p.background + ";" : ""};

    h2 {
        color: #1A2635;
        font-weight: 700;
        font-size: 28px;
        line-height: 30px;
    }
    h3 {
        color: #1A2635;
        font-weight: 700;
        font-size: 20px;
    }
    * {
        max-width: 1000px;
    }
    @media only screen and (max-width: 860px) {
        padding: 30px;
    }
`;

export default StyledSection;