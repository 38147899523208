import styled from "styled-components";
import Carousel from 'react-bootstrap/Carousel';
import img1 from '../assets/fotos/1.jpg';
import img2 from '../assets/fotos/2.jpg';
import img3 from '../assets/fotos/3.jpg';
import img4 from '../assets/fotos/4.jpg';

const Image = styled.img`
  object-fit: cover;
  width: 600px;
  height: 300px;
`;
const StyledCarousel = styled(Carousel)`
  width: 100%;
  max-width: 600px;
`;
const Gallery = () => {
    return (
      <StyledCarousel>
        <Carousel.Item>
          <Image
            src={img1}
            alt="Projeto Sonhe"
          />
        </Carousel.Item>
        <Carousel.Item>
          <Image
            src={img2}
            alt="Projeto Sonhe"
          />
        </Carousel.Item>
        <Carousel.Item>
          <Image
            src={img3}
            alt="Projeto Sonhe"
          />
        </Carousel.Item>
        <Carousel.Item>
          <Image
            src={img4}
            alt="Projeto Sonhe"
          />
        </Carousel.Item>
      </StyledCarousel>
    )
}
export default Gallery;