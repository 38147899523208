import styled from "styled-components";

const Button = styled.button`
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    user-select: none;
    background: none;
    color: #fff;

    &:hover {
        background: rgba(255,255,255,0.1);
        transition: background ease-out .5s;
    }
    &:active {
        background: rgba(255,255,255,0.3);
        transition: background ease-out .2s;
    }
    transition: background ease-out .2s;
`;
const PrimaryButton = styled(Button)`
    background: #BF813B;
    padding: 10px 20px;

    &:hover {
        background: #cb9251;
    }
    &:active {
        background: #ca9b67;
    }
`;
export default Button;
export {PrimaryButton};